import { combineReducers } from 'redux';

import menu from './menu';

import app from '~/services/app/reducer';
import dataCache from '~/services/cache/reducer';
import session from '~/services/session/reducer';
import ui from '~/services/ui/reducer';

export default routeReducer =>
    combineReducers({
        app,
        menu,
        dataCache,
        router: routeReducer,
        session,
        ui
    });
